* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins";
  overflow-x: hidden;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
}

* {
  scroll-behavior: smooth;
  transition: 0.3s all;
}

button,
.button {
  font-family: "Neue Machina", Arial, Helvetica,;
  width: 205px;
  padding: 18px 10px !important;
  margin: 0 !important;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  font-size: 16px !important;
  text-transform: uppercase;
  border: 0;
  color: #ffffff;
  background: #00094c;
  box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  border: 0 solid;
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);

  &.secondary {
    background: #001eff;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  }

  &.light {
    border: 1px solid #d9d9d9;
    background: transparent;
  }

  &:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  &.large {
    padding: 18px 30px !important;
  }

  @media (max-width: 768px) {
    padding: 14px 30px !important;
    font-size: 16px;
  }

  &.slide-next {
    @media (max-width: 768px) {
      margin-top: 20px;
      transform: scale(0.9);
    }
  }
}

ul,
ol,
menu {
  list-style-type: none;
}

a {
  margin: 20px;
  font-size: 20px;
  color: #111111;
  text-decoration: none;
  background: linear-gradient(0deg, rgb(180, 180, 180), rgb(239, 239, 239)) no-repeat right bottom / 0 var(--bg-h);
  transition: background-size 350ms;
  --bg-h: 100%;
  text-decoration: none;
  color: inherit;
  line-height: 3;
  padding-bottom: 5px;
  --bg-h: 5px;
}

h1 {
  font-family: "Neue Machina", Arial, Helvetica,;
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;

  color: #00094c;

  @media (max-width: 768px) {
    font-size: 30px !important;
  }
}

h2 {
  font-family: "Neue Machina", Arial, Helvetica,;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 46px;
 

  color: #00094c;

  @media (max-width: 700px) {
    font-size: 28px;
  }
}

h3 {
  font-family: "Neue Machina", Arial, Helvetica,;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 31px;
  color: #00094c;
}

p {
  font-family: "Poppins", Arial, Helvetica,;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 33px;
  color: #333333;

  &.md {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #333333;
  }

  &.sm {
    font-weight: 400;
    font-size: 12px;
    line-height: 165.5%;
    /* or 23px */

    letter-spacing: 0.005em;
  }

  &.line-text {
    line-height: 25px !important;
  }

  @media (max-width: 1400px) {
    font-size: 18px;
  }

  @media (max-width: 768px) {
    font-size: 12px !important;
    line-height: 20px !important;
  }
}

small {
  font-family: "Neue Machina", Arial, Helvetica,;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 137%;
  display: block;
  color: #00094c73;
  position: relative;

  &::before {
    width: 20px;
    height: 2px;
    bottom: 8px;
    position: relative;
    display: inline-block;
    background-color: #001eff;
    content: "";
    margin-right: 16px;
    animation: lineUp 4s linear infinite;

    @keyframes lineUp {
      50% {
        width: 70px;
      }
    }

    @media (max-width: 768px) {
      display: block;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }
}
