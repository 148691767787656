@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Bold.eot");
    src: local("Neue Machina Bold"), local("NeueMachina-Bold"),
        url("../fonts/NeueMachina-Bold.eot?#iefix") format("embedded-opentype"),
        url("../fonts/NeueMachina-Bold.woff2") format("woff2"), url("../fonts/NeueMachina-Bold.woff") format("woff"),
        url("../fonts/NeueMachina-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Regular.eot");
    src: local("Neue Machina Regular"), local("NeueMachina-Regular"),
        url("../fonts/NeueMachina-Regular.eot?#iefix") format("embedded-opentype"),
        url("../fonts/NeueMachina-Regular.woff2") format("woff2"),
        url("../fonts/NeueMachina-Regular.woff") format("woff"),
        url("../fonts/NeueMachina-Regular.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}


