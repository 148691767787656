.container {
    margin: 0 auto;
    @media (min-width: 1440px) {
        max-width: 1400px !important;
    }
    @media (max-width: 1440px) {
        padding-left: 60px !important;
        padding-right: 60px !important;
    }
    @media (max-width: 1200px) {
        padding-left: 40px !important;
        padding-right: 40px !important;
    }
    @media (max-width: 767px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
}
.devider {
    padding: 50px 0;
    display: block;
    &.half {
        padding: 15px 0;
    }
    &.md {
        padding: 30px 0;
    }
    @media (max-width: 768px) {
        padding: 20px 0;
    }
}
.flex {
    display: flex;
    &.column {
        flex-direction: column;
    }
    &.align-center {
        align-items: center;
    }
    &.align-start {
        align-items: flex-start;
    }
    &.align-end {
        align-items: flex-end;
    }
    &.justify-center {
        justify-content: center;
    }
    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-between {
        justify-content: space-between;
    }
    &.justify-end {
        justify-content: flex-end;
    }
    &.mobile-column {
        @media (max-width: 768px) {
            flex-direction: column;
        }
    }
    &.mobile-column-reverse {
        @media (max-width: 768px) {
            flex-direction: column-reverse;
        }
    }
    &.mobile-justify-end {
        @media (max-width: 768px) {
            justify-content: flex-end;
        }
    }

    &.text-mobile-center {
        @media (max-width: 768px) {
            * {
                text-align: center !important;
            }
        }
    }
}

.mobile-text-center {
    @media (max-width: 768px) {
        text-align: center !important;
        display: block;
        width: 100%;
    }
}

.text-center {
    text-align: center;
}
